$enable-shadows: true;
$primary: hsl(223, 60%, 29%);
$secondary: hsl(38, 37%, 51%);
$secondary-light: hsl(38, 37%, 85%);
$light: hsl(211, 71%, 93%);
$light-transp: hsl(0deg, 0%, 100%, .85);
$bg-white-transp: hsl(0, 0%, 100%, .66);
$bg-black-transp: hsl(0, 0%, 0%, .75);
$dark: hsl(40, 4%, 14%);
$link-color:  hsl(234, 60%, 50%);
$frosted-glass-blur: blur(10px);
$btn-border-radius: 0;
$modal-backdrop-opacity: 0.25;
$inner-shadow: inset 0 0.5rem 1rem hsl(0 0% 0% / .15);
$nav-link-padding-y: 1rem;