html,
body {
    width: 100%;
    height: 100%;
}

.burger {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.patty {
    flex-grow: 1;
}

.top-bun,
.patty,
.bottom-bun {
    flex-shrink: 0;
}

body,
.bg-gray-100 {
    background-color: $gray-100;    
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    font-family: var(--my-headings-font-family);
    font-weight: var(--my-headings-font-weight);
}

img,
video {
    max-width: 100%;
    pointer-events: none;
}

p:last-child, dl:last-child, dd:last-child, ul:last-child, ol:last-child, address:last-child {margin-bottom: 0;}

.text-black {
    color: $black;
}

.text-white {
    color: $white;
}
.text-link {
    color: $link-color;
}

.navbar-brand {
    font-family: var(--my-headings-font-family);
    color: $dark;
}

.bg-black {
    background-color: $black;
}

.bg-light-transp {
    background-color: $light-transp;
}

.bg-white-transp {
    background-color: $bg-white-transp;
}

.bg-black-transp {
    background-color: $bg-black-transp;
}

.bg-secondary-light {
    background-color: $secondary-light;
}

.splide__slide {
    opacity: 0.5;
    transition: 0.25s;
}

.splide .is-active,
.splide__slide:hover {
    opacity: 1;
}

.splide__slide img {
    height: 100%;
}

.splide__arrow {
    width: 2.5em;
    height: 2.5em;
    border-radius: .15em;
    opacity:0.85;
    background-color: $light;
}

.splide__arrow:hover {
    opacity: 1;
}

@media (prefers-reduced-motion) {
    .splide__slide {
        transition: none;
    }
}

.offcanvas iframe {
    min-height: initial !important;
}

.clip-circle {
    clip-path: circle();
    aspect-ratio: 1 / 1;
    width: 85%;
    box-sizing: content-box;
    overflow: hidden;
}

.clip-4x5 {
    clip-path: inset(0);
    aspect-ratio: 4 / 5;
    overflow: hidden;
}

.frosted-glass {
    backdrop-filter: $frosted-glass-blur;
}

#redirect-suppress {
    display: none;
}

.inner-shadow {
    box-shadow: $inner-shadow;
}

.hero-frame, .hero-frame-xxl {
    max-height: 33vh !important;
    overflow: hidden !important;
}

.hero-frame-img {
    display: block;
    margin: auto;
}

.hero-frame-xxl img {
    margin-top: -8%;
}