:root {
    --bs-font-sans-serif: 'poppins';
    --my-headings-font-family: 'dunbar-tall';
    --my-headings-font-weight: 500;
}

.navbar {
    --bs-navbar-toggler-border-color: null;
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.8%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    --bs-navbar-toggler-padding-x: 0;
}