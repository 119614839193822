@import "~bootstrap/scss/functions";
@import "./abstracts/variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/utilities/api";
@import "./abstracts/map-amends";
@import "~bootstrap/scss/bootstrap";
@import "./abstracts/css-variables";
@import "./abstracts/custom-css";
@import '../../../node_modules/@splidejs/splide/dist/css/splide.min.css';